.ad{
    height: 600px;
    position: relative;
}

.ad video{
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.ad .overlay{
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    background-color: #0000005c;
}

.ad .ad-content{
    height: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;
    gap: 15px;
}

.ad .ad-content h3{
    font-size: 12px;
    line-height: 18px;
    font-weight: 700;
    font-style: normal;
    text-transform: uppercase;
    letter-spacing: 1.6px;
    color: #fff;
}

.ad .ad-content h1{
    width: 58%;
    font-size: 42px;
    color: #fff;
    margin-bottom: 40px;
    text-align: center;
}

.ad .ad-content a{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 65px;
    background-color: #af8b43;
    color: #fff;
    font-size: 15px;
    margin-top: 28px;
}



@media(max-width:950px){
    .ad .ad-content h1 {
        width: 100%;
        font-size: 30px;
        color: #fff;
        margin-bottom: 40px;
        text-align: center;
    }
}