.rates{
    padding-top: 180px;
    padding-bottom: 180px;
    background-color: #201f1f;
}

.rates .rate-people{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
}


.rates .rate-people .image{
    position: relative;
}

.rates .rate-people .image img{
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
}

.rates .rate-people .image span{
    background-color: #b08c44;
    color: #fff;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    position: absolute;
    bottom: 0;
    right: 0;
}


.rates .rate-text .text{
    color: #fff;
    font-size: 18px;
}

.rates .buttons{
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 21px;
}

.rates .buttons button{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: none;
    outline: none;
    background-color: transparent;
    color: #fff;
    border: 1px solid #fff;
}