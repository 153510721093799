footer{
    padding-top: 150px;
    padding-bottom: 30px;
    background-color: #201f1f;
}


footer .footer-content h1{
    width: 58%;
    font-size: 42px;
    color: #fff;
    margin: 0;
}

footer .footer-content h2{
    width: 58%;
    font-size: 42px;
    color: #af8b43;
    margin-bottom: 40px;
}

footer .footer-content .short-links{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #ffffff2b;
}

footer .footer-content .short-links ul{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    padding: 0;
    color: #fff;
}


footer .footer-content .short-links ul li a{
    color: #fff;
    font-size: 18px;
}

footer .footer-content .short-links .socials{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}

footer .footer-content .short-links .socials a{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    color: #fff;
    font-size: 20px;
    border-radius: 50%;
}

footer .footer-content .short-links .socials a:nth-child(1){
    background-color: #0866ff;
}


footer .footer-content .short-links .socials a:nth-child(2){
    background: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%),
            radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%),
            radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%),
            radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%),
            radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%),
            radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%),
            radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent),
            linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
}

footer .footer-content .short-links .socials a:nth-child(3){
    background-color: #0a66c2;

}

footer .footer-content .copy-right h5{
    padding-bottom: 10px;
    font-size: 16px;
    color: #fff;
    font-weight: 200;
}



@media(max-width:950px){
    footer .footer-content .short-links ul {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;
        padding: 0;
        color: #fff;
        flex-wrap: wrap;
    }


    footer .footer-content .short-links {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;
        padding-bottom: 30px;
        border-bottom: 1px solid #ffffff2b;
        gap: 50px;
    }
}