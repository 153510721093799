.our-services {
    min-height: 355px;
    background-color: #201f1f;
}

.our-services .services-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 82px;
    padding-bottom: 60px;
}

.our-services .services-header .left{
    width: 50%;
}

.our-services .services-header .left h2{
    font-size: 12px;
    line-height: 18px;
    font-weight: 700;
    font-style: normal;
    text-transform: uppercase;
    letter-spacing: 1.6px;
    color: #fff;
}


.our-services .services-header .left h1{
        width: 58%;
        font-size: 42px;
        color: #fff;
        margin-bottom: 40px;
}

.our-services .services-header .right{
    width: 50%;
}

.our-services .services-header .right p{
    font-size: 17px;
    width: 100%;
    color: #c9c9c9;
    margin: 0;
    line-height: 24px;
    margin-bottom: 40px;
}

.our-services .service{
    min-height: 300px;
    border-right: 1px solid #ffffff0f;
    margin-bottom: 90px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.our-services .service>i{
    font-size: 90px;
    color: #af8b43;
}


.our-services .service h3{
    font-size: 20px;
    color: #fff;
    margin-top: 20px;
    margin-bottom: 18px;
    font-weight: 400;
}

.our-services .service a{
    font-size: 16px;
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 6px;
}


@media(max-width:950px){
    .our-services .services-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding-top: 82px;
        padding-bottom: 60px;
    }

    .our-services .services-header .left {
        width: 100%;
    }

    .our-services .services-header .right {
        width: 100%;
    }

    .our-services .services-header .left h1 {
        width: 100%;
        font-size: 42px;
        color: #fff;
        margin-bottom: 40px;
    }
}