
@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700;800;900&display=swap');



body{
  background-color: #fff;
  scroll-behavior: smooth;

}

*{
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  font-family: "Tajawal", sans-serif;

  
}


/* font-family: 'IBM Plex Sans Arabic', sans-serif; */

a{
  text-decoration: none !important;
}

ul{
  list-style: none !important;
  margin: 0 !important;
}


.hide{
  display: none !important;
}




.lang a{
  position: absolute;
  z-index: 5;
  left: 15px;
  top: 34px;
  color: #fff;
  font-size: 21px;
}


p{
  text-transform: math-auto;
}


/* Ar Lang */


nav.ar .nav-content {
  direction: rtl;
}


.landing.ar .landing-text {
  direction: rtl;
}


.our-services.ar .services-header {
  direction: rtl;
}

.contact.ar {

  direction: rtl;
}


footer.ar {

  direction: rtl;
}

.about-us.ar .about-text {
  direction: rtl;
}

.partners.ar {
  direction: rtl;
}


.apartment.ar .apartment-text ul li {
  display: list-item;
  color: #000;
  list-style: disc;
  direction: rtl;
  margin-right: 30px;
}

.apartment.ar .apartment-text ul {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 15px;
}