.bestdeals {
    position: relative;
    direction: rtl;
    margin-top: 80px;
}

.bestdeals>img{
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    object-fit: cover;
    opacity: 0.1;
}





.bestdeals .bestdeals-header h2{
    font-size: 12px;
    line-height: 18px;
    font-weight: 700;
    font-style: normal;
    text-transform: uppercase;
    letter-spacing: 1.6px;
}

.bestdeals .bestdeals-header h1{
    width: 80%;
    font-size: 42px;
    color: #000;
    margin-bottom: 40px;
}

.bestdeals .apartment {
    border: 1px solid #0000000a;
    margin-bottom: 63px;
    background-color: #fff;
}




.bestdeals .apartment .image img{
    width: 100%;
    height: 400px;
    object-fit: cover;
    transition: 0.5s;
    scale: 1;
}

.bestdeals .apartment .image img:hover{
    scale: 1.1;
}
.bestdeals .apartment .text{
    padding: 15px;
}

.bestdeals .apartment .text div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
}

.bestdeals .apartment .text div h4 {
    font-size: 21px;
    color: #000;
}

.bestdeals .apartment .text div h4 span {
    color: #bc9230;
    font-size: 21px;
}


.bestdeals .apartment .text h1 {
    font-size: 29px;
    color: #000;
    font-weight: 500;
    margin-bottom: 0;
    font-weight: 500;
}

.bestdeals .apartment .text h2 {
    margin: 0;
    font-size: 15px;
    color: #aaa;
    font-weight: 400;
    margin-top: 15px;
    font-size: 18px;
    font-weight: 400;
    color: #828282;
    direction: rtl;
    text-align: left;
}


.bestdeals .apartment .text ul{
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    margin-top: 25px !important;
}

.bestdeals .apartment .text ul li{
    padding-bottom: 10px;
    border-bottom: 1px solid #00000017;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}




.bestdeals .apartment .text ul li span:nth-child(2){
    font-size: 15px;
    color: #9f9f9f;
}

.bestdeals .number{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 150px;
}

.bestdeals .number h3{
    font-size: 25px;
    color: #000;
    font-weight: 500;
    margin-bottom: 0;
}

.bestdeals .number h1{
    font-size: 45px;
    color: #af8b43;
    font-weight: 500;
    margin-bottom: 0;
}