.contact{
    padding-top: 150px;
    padding-bottom: 150px;
    background-color: #201f1f;
}

.contact .contact-content{
    font-size: 17px;
    width: 100%;
    color: #c9c9c9;
    margin: 0;
    line-height: 24px;
    margin-bottom: 40px;
    text-transform: lowercase;
}

.contact .contact-content h2{
    font-size: 12px;
    line-height: 18px;
    font-weight: 700;
    font-style: normal;
    text-transform: uppercase;
    letter-spacing: 1.6px;
    color: #fff;
}

.contact .contact-content h1{
    width: 58%;
    font-size: 42px;
    color: #fff;
    margin-bottom: 40px;
}


.contact .contact-content ul{
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 30px !important;
}


.contact .contact-content ul li a{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    color: #fff;
    text-transform: capitalize;
}

.contact .contact-content ul li a i{
    font-size: 20px;
    color: #af8b43;
}

.contact .contact-form{
    width: 100%;
}

.contact .contact-form .inputs{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
}

.contact .contact-form .inputs .input{
    width: 100%;
    position: relative;
    margin-right: 10px;
}

.contact .contact-form .inputs .input label{
    position: absolute;
    top: 27px;
    left: 5px;
    color: #fff;
    transform: translateY(-50%);
}

.contact .contact-form .inputs .input input{
    width: 100%;
    height: 50px;
    border: none;
    outline: none;
    background-color: transparent;
    color: #fff;
    font-size: 16px;
    border-bottom: 1px solid #ffffff2e;
    padding-left: 30px;
}

.contact .contact-form .inputs .input textarea{
    width: 100%;
    height: 150px;
    border: none;
    outline: none;
    background-color: transparent;
    color: #fff;
    font-size: 16px;
    border-bottom: 1px solid #ffffff2e;
    padding-left: 30px;
}

.contact .contact-form form{
    width: 100%;
}

.contact .contact-form form .buttons{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.contact .contact-form form .buttons button{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 250px;
    height: 50px;
    gap: 7px;
    border: none;
    outline: none;
    background-color: #af8b43;
    color: #fff;
}

.contact .contact-form form .buttons label{
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.contact .contact-form form .buttons label input{
    background-color: transparent;
    color: transparent;
    border: none;
    outline: none;
    width: 20px;
    height: 19px;
    border-color: transparent;
}


.contact.ar .contact-content ul li a {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 10px;
    color: #fff;
    text-transform: capitalize;
    direction: ltr;
}

.contact .contact-content ul li:nth-child(3) a {
    text-transform: lowercase;
}


@media(max-width:950px){
    .contact .contact-form form .buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        gap: 28px;
    }

    .contact .contact-content h1 {
        width: 100%;
        font-size: 42px;
        color: #fff;
        margin-bottom: 40px;
    }

    .contact.ar .contact-form .inputs {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 40px;
        flex-wrap: wrap;
        direction: rtl;
    }

    .contact.ar .contact-form .inputs .input input {
        direction: rtl;
    }
}