

.map .map-content{
    position: relative;
}


.map .map-content .logo img{
    width: 128px;
    background-color: #201f1f;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
}