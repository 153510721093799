nav {
    position: relative;
    z-index: 15;
    width: 100%;
    background-color: #fff;
}

nav .nav-content{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}


nav .nav-content .logo{
    width: 15%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}


nav .nav-content .logo img {
    width: 156px;
    height: 115px;
    object-fit: contain;
}

nav .nav-content .nav-eles {
    width: 70%;
}

nav .nav-content .nav-eles ul{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 27px;
    padding: 0;
}


nav .nav-content .nav-eles ul li a {
    color: #000;
    font-size: 17px;
    font-weight: 400;
    padding-bottom: 5px;
    border-bottom: 0px solid #000;
    transition: 0.5s;

}

nav .nav-content .nav-eles ul li{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
}

nav .nav-content .nav-eles ul li a img{
    width: 20px;
    height: 25px;
    object-fit: contain;
    
}


nav .nav-content .nav-eles ul li a.active{
    border-bottom: 2px solid #000;

}


nav .nav-content .nav-eles ul li a:hover{
    border-bottom: 2px solid #000;
}

nav .nav-content .contact-info{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    width: 5%;
    justify-content: flex-start;
}

nav .nav-content .contact-info>a:nth-child(1){
    font-size: 26px;
    color: #000;
}

nav .nav-content .contact-info>button{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    background-color: transparent;
    outline: none;
    border: none;
    color: #000;
}

nav .nav-content .contact-info .side-contact-info{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 350px;
    background-color: #fff;
    padding: 35px;
    transition: 0.5s;
    right: -200%;
}

nav .nav-content .contact-info .side-contact-info.active{
    right: 0;
}

nav .nav-content .contact-info .side-contact-info .side-content>div{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}


nav .nav-content .contact-info .side-contact-info .side-content>div img{
    width: 120px;
}

nav .nav-content .contact-info .side-contact-info .side-content>div button{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    background-color: #00000017;
    border-radius: 50%;
    color: #000;
    outline: none;
    border: none;
    font-size: 24px;
}

nav .nav-content .contact-info .side-contact-info .side-content ul{
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 21px;
    margin-top: 50px !important;
    margin-bottom: 50px !important;
    padding-bottom: 20px;
    border-bottom: 1px solid #00000038;
}


nav .nav-content .contact-info .side-contact-info .side-content ul li a{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 17px;
    color: #000;
    font-size: 21px;
}


nav .nav-content .contact-info .side-contact-info .side-content ul li {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
}

nav .nav-content .contact-info .side-contact-info .side-content ul li a img{
    width: 20px;
    height: 25px;
    object-fit: contain;
    
}



nav .nav-content .contact-info .side-contact-info .side-content>a:nth-child(3){
    width: 100%;
    display: flex;
    font-size: 25px;
    color: #000;
    letter-spacing: 1px;
}


nav.ar .nav-content .contact-info .side-contact-info .side-content>a:nth-child(3){
    width: 100%;
    display: flex;
    font-size: 25px;
    color: #000;
    letter-spacing: 1px;
    direction: ltr;
}

nav .nav-content .contact-info .side-contact-info .side-content>a:nth-child(4){
    font-size: 15px;
    color: #a7a7a7;
}


nav.ar .nav-content .contact-info .side-contact-info .side-content>a:nth-child(4) {
    font-size: 15px;
    color: #a7a7a7;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
}





#open-nav{
    background-color: transparent;
    outline: none;
    border: none;
    font-size: 31px;
    display: none;
    color: #000;
}

#close-nav{
    background-color: transparent;
    outline: none;
    border: none;
    font-size: 31px;
    display: none;
}


@media(max-width:950px){

    
    #open-nav{
        display: block;
    }

    #close-nav{
        display: block;
    }

    nav .nav-content .nav-eles {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 60px;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #fff;
        height: 100%;
        width: 100%;
        justify-content: center;
        z-index: 15015;
    }




    nav .nav-content .nav-eles ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 40px;
        padding: 0;
        justify-content: center;
        align-items: center;
        margin: 0 !important;

    }

    
    nav .nav-content .nav-eles{
        display: none;
    }

    nav .nav-content .nav-eles ul li a {
        color: #000;
        font-size: 17px;
        font-weight: 500;
    }

    nav {
        position: absolute;
        width: 100%;
        left: 0;
        right: 0;
        z-index: 15;
        padding-top: 10px;
        padding-bottom: 10px;
        border: 1px solid #ffffff21;
    }

    nav .nav-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
    nav.active{
        position: inherit !important;
    }


    nav .nav-content .contact-info {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;
        width: 24%;
        justify-content: flex-start;
    }



    nav .nav-content .nav-eles ul li {
        align-items: center;
        display: flex;
        flex-direction: row;
        gap: 15px;
        width: 164px;
        text-align: left;
        justify-content: flex-start;
    }


    nav.ar .nav-content .nav-eles ul li {
        align-items: center;
        display: flex;
        flex-direction: row;
        gap: 15px;
        width: 100px;
        text-align: left;
        justify-content: flex-end;
    }

    nav.ar .nav-content .nav-eles ul li {
    justify-content: flex-start;
    width: 100px;
}

}

