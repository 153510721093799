.about-us{
    position: relative;
    margin-top: 110px;
    margin-bottom: 120px;
}


.about-us>img{
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    z-index: -1;
}


.about-us .about-images{
    position: relative;
    min-height: 900px;
}

.about-us .about-images div{
    position: absolute;
    top: -35px;
    right: 111px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}


.about-us .about-images div h1{
    font-size: 112px;
    letter-spacing: 5px;
    margin: 0;
    height: 116px;
    color: #af8b43;
}

.about-us .about-images div h2{
    width: 43%;
    font-size: 20px;
    margin: 0;
    color: #af8b43;
}


.about-us .about-images .lg-img {
    width: 70%;
    height: 637px;
    object-fit: cover;
    margin-top: 35px;
}

.about-us .about-images .sm-img {
    width: 77%;
    position: absolute;
    height: 350px;
    object-fit: cover;
    bottom: 60px;
    right: 42px;
}

.about-us .about-text{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    min-height: 700px;
}

.about-us .about-text h3{
    font-size: 12px;
    line-height: 18px;
    font-weight: 700;
    font-style: normal;
    text-transform: uppercase;
    letter-spacing: 1.6px;
}

.about-us .about-text h1{
    width: 80%;
    font-size: 42px;
    color: #000;
    margin-bottom: 40px;
}

.about-us .about-text p{
    font-size: 17px;
    width: 100%;
    color: #858585;
    margin: 0;
    line-height: 24px;
    margin-bottom: 40px;
}

.about-us .about-text h4{
    width: 100%;
    font-size: 15px;
    color: #000;
}

.about-us .about-text a{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 65px;
    background-color: #af8b43;
    color: #fff;
    font-size: 15px;
    margin-top: 28px;
}


@media(max-width:950px){
    .about-us .about-images {
        position: relative;
        min-height: 650px;
        padding-top: 50px;
    }

    .about-us .about-images .lg-img {
        width: 60%;
        height: 400px;
        object-fit: cover;
        margin-top: 35px;
    }

    .about-us .about-images .sm-img {
        width: 60%;
        position: absolute;
        height: 350px;
        object-fit: cover;
        bottom: 0;
        right: 42px;
    }

    .about-us .about-images div h1 {
        font-size: 112px;
        letter-spacing: 5px;
        margin: 0;
        height: 116px;
        color: #af8b43;
        margin-top: 29px;
    }
}