.partners{

}


.partners .partner-img{
    height: 600px;
    margin-top: 60px;
    margin-bottom: 100px;

    position: relative;
}


.partners .partner-img>img:nth-child(1){
    width: 70%;
    height: 100%;
    object-fit: cover;
    object-position: top;
}

.partners .partner-img>img:nth-child(2){
    width: 80%;
    height: 300px;
    position: absolute;
    bottom: -20px;
    right: 0;
    border: 19px solid #fff;
    object-fit: cover;
    border: 10px solid #fff;
}

.partners .partner-img span{
    position: absolute;
    top: 20px;
    left: 67%;
    font-size: 75px;
    transform: translateX(-50%);
    font-size: 120px;
    font-weight: 600;
    color: #efc66a;
}



.partners .partner-text{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 60px;
}


.partners .partner-text h1{
    font-size: 53px;
    font-weight: 500;
    color: #000;
}

.partners .partner-text p {
    font-size: 18px;
    width: 77%;
    color: #848484;
    font-weight: 300;
}