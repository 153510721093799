.landing{
    position: relative;
    height: 100vh;
}


.landing-img{
    height: 100%;
}
.landing .landing-back-slider{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}

.landing .landing-back-slider .swiper-slide{
    width: 100% !important;
    height: 100vh;
}

.landing .landing-back-slider .landing-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.landing .overlay{
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    background: rgb(0,0,0);
    background: linear-gradient(225deg, rgba(0,0,0,0.1966036414565826) 0%, rgba(0,0,0,0.9304971988795518) 100%);
    z-index: 2;
}

.landing .landing-text {
    height: 100vh;
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 25px;
}


.landing .landing-text h1{
    font-size: 60px;
    color: #fff;
}
.landing .landing-text h1:nth-child(2){
    margin-left: 70px;
}

.landing .landing-text div{
    margin-left: 70px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    color: #fff;
    margin-top: 19px;
    margin-bottom: 22px;
}

.landing .landing-text div i{
    font-size: 47px;
}


.landing .landing-text div p
{
    font-size: 18px;
    width: 53%;
    color: #9e9e9e;
    margin: 0;

}

.landing .landing-text a{
    margin-left: 70px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 65px;
    background-color: #af8b43;
    color: #fff;
    font-size: 15px;
    margin-top: 28px;
}


.swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 4px);
    background-color: transparent;
    border: 2px solid #fff;
    position: relative;
    z-index: 2;
    width: 10px;
    height: 10px;
}


.swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
    bottom: var(--swiper-pagination-bottom, 8px);
    top: var(--swiper-pagination-top, auto);
    left: 0;
    width: 100%;
    z-index: 18;
    position: absolute;
}


@media(max-width:950px){
    .landing .landing-text h1 {
        font-size: 41px;
        color: #fff;
    }

    .landing .landing-text div {
        margin-left: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        color: #fff;
        margin-top: 19px;
        margin-bottom: 22px;
    }


    .landing .landing-text div p {
        font-size: 18px;
        width: 100%;
        color: #9e9e9e;
        margin: 0;
    }
}