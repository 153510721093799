.apartment{

}

.apartment .apartment-header{
    height: 600px;
    position: relative;
}

.apartment .apartment-header .overlay{
    inset: 0;
    width: 100%;
    height: 100%;
    background-color: #000000a3;
    position: absolute;
    z-index: 2;
}

.apartment .apartment-header>img:nth-child(2){
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
}

.apartment .apartment-header>img:nth-child(3){
    position: absolute;
    width: 250px;
    height: 250px;
    object-fit: contain;
    right: 10%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
}

.apartment .apartment-header .text{
    height: 600px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    z-index: 5;
    padding: 50px;
    justify-content: center;
}

.apartment .apartment-header .text a{
    font-size: 20px;
    font-weight: 300;
    color: #ddb357;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
}

.apartment .apartment-header .text h1{
    font-size: 80px;
    color: #d8ad51;
    margin-top: 30px;
    margin-bottom: 30px;
}

.apartment .apartment-header .text h2{
    font-size: 16px;
    color: #fff;
}

.apartment .apartment-header .text ul{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    color: #fff;
    width: 35%;
    margin-top: 60px !important;
}

.apartment .apartment-header .text ul li{
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 17px;
    font-size: 19px;
    font-weight: 300;
}

.apartment .apartments-images{
    margin-top: 60px ;
    margin-bottom: 60px;
    position: relative;
}

.apartment .apartments-images img{
    width: 100%;
    height: 450px;
    object-fit: contain;
}


.apartment .apartments-images .buttons{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
}

.apartment .apartments-images .buttons button{
    background-color: transparent;
    outline: none;
    border: none;
    background-color: #dcb2564a;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    font-size: 18px;
    color: #fff;
}

.apartment .apartment-text{
    margin-top: 60px;
    margin-bottom: 60px;
}

.apartment .apartment-text ul{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
}

.apartment .apartment-text ul li{
    display: list-item;
    color: #000;
    list-style: disc;
}


@media(max-width:950px){
    .apartment .apartment-header {
        height: 890px;
        position: relative;
    }

    .apartment .apartment-header .text ul {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        color: #fff;
        width: 100%;
        margin-top: 20px !important;
        padding: 0;
    }

    .apartment .apartment-header .text h1 {
        font-size: 44px;
        color: #d8ad51;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .apartment .apartment-header .text {
        height: 828px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: relative;
        z-index: 5;
        padding: 50px;
        justify-content: center;
    }


    .apartment .apartment-header>img:nth-child(3) {
        position: absolute;
        width: 250px;
        height: 250px;
        object-fit: contain;
        left: 50%;
        right: inherit;
        top : inherit;
        bottom: 0%;
        transform: translateX(-50%);
        z-index: 3;
    }
}