
.categories .categories-content{
    
    display: flex;
    flex-direction: row;
    align-items: center;
}

.categories .categories-content .lg-image{
    width: 50%;
    position: relative;
}

.categories .categories-content .lg-image img{
    width: 100%;
    height: 800px;
    object-fit: cover;
}

.categories .categories-content .lg-image .text{
    position: absolute;
    bottom: 20px;
    left: 20px;
}

.categories .categories-content .lg-image .text h1{
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #fff;
    justify-content: center;
    font-size: 21px;
    font-weight: 300;
}

.categories .categories-content .lg-image .text h2{
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 250px;
    height: 50px;
    background-color: #af8b43;
    color: #fff;
    justify-content: center;
    font-size: 21px;
    font-weight: 400;
}

.categories .categories-content .sm-images{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
}

.categories .categories-content .sm-images>div{
    position: relative;
    width: 33.3333333%;
}

.categories .categories-content .sm-images>div img{
    width: 100%;
    height: 400px;
    object-fit: cover;
    transition: 0.5s;
}

.categories .categories-content .sm-images>div .text{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    inset: 0;
    height: 100%;
    background-color: #00000069;
    transition: 0.5s
}


.categories .categories-content .sm-images>div .text:hover{
    background-color: #0000009e;

}

.categories .categories-content .sm-images>div .text h1{
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #fff;
    justify-content: center;
    font-size: 21px;
    font-weight: 300;
}

.categories .categories-content .sm-images>div .text h2{
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 200px;
    height: 45px;
    background-color: #af8b43;
    color: #fff;
    justify-content: center;
    font-size: 21px;
    font-weight: 400;


    
}

.categories .categories-content .sm-images a {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    color: #000;
    width: 138px;
    height: 40px;
    font-weight: 400;
}



@media(max-width:950px){

    .categories .categories-content .sm-images>div {
        position: relative;
        width: 100%;
    }
}