.page-header{
    height: 500px;
    position: relative;

}

.page-header>img{
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.page-header .overlay{
    inset: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #0000008a;
}



.page-header .page-header-text{
    position: relative;
    height: 500px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
}


.page-header .page-header-text h1{
    font-size: 65px;
    font-weight: 500;
    color: #fff;
}


.page-header .page-header-text ul{
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 25px;
    font-size: 18px;
    color: #fff;
}

.page-header .page-header-text ul li{
    display: list-item;
}

.page-header .page-header-text ul li a{
    color: #fff;
    display: list-item;
}




@media(max-width:950px){
    .page-header .page-header-text h1 {
        font-size: 43px;
        font-weight: 500;
        color: #fff;
    }

    .page-header .page-header-text {
        position: relative;
        height: 600px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 30px;
    }
}